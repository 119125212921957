document.addEventListener("turbolinks:load", function() {
    const amountField = document.getElementById("amount-field-request").getAttribute("data-preloaded-amount");
    const optionsSelect = document.getElementById("options-select-request");
    console.log(amountField)
    // Define your options here
    const optionSets = {
        range0: (amount) => amount >= 0 && amount < 300 ? ["Una sola exibición sin"] : [],
        range1: (amount) => amount >= 300 && amount < 600 ? ["Una sola exibición sin","3"] : [],
        range2: (amount) => amount >= 600 && amount < 900 ? ["Una sola exibición sin","3", "6"] : [],
        range3: (amount) => amount >= 900 && amount < 1000 ? ["Una sola exibición sin","3", "6", "9"] : [],
        range4: (amount) => amount >= 1000 && amount < 1800 ? ["Una sola exibición sin","3", "6", "9", "12"] : [],
        range5: (amount) => amount >= 1800 && amount <= 1000000 ? ["Una sola exibición sin","3", "6", "9", "12", "18"] : [],
    };
  
    // Function to update the select options
    function updateOptions() {
      const amount = parseFloat(amountField);
      let selectedOptions = [];
  
      for (const range in optionSets) {
        if (optionSets.hasOwnProperty(range)) {
          if (optionSets[range](amount).length > 0) {
            selectedOptions = optionSets[range](amount);
            break; // El primer rango que coincida establecerá las opciones
          }
        }
      }

      optionsSelect.innerHTML = "";
      optionsSelect.appendChild(new Option("Selecione una opción", "")); // Add a placeholder
      selectedOptions.forEach((option) => {
        const optionElement = document.createElement("option");
        optionElement.value = option;
        optionElement.text = `${option} MSI`;
        optionsSelect.appendChild(optionElement);
      });
    }
    // Actualizar inicialmente las opciones
    updateOptions();
  });
  