document.addEventListener("turbolinks:load", function() {
  let mapContainer = document.getElementById('map-container')

  if (mapContainer) {
    // Create the script tag, set the appropriate attributes
    var script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAeSquqiv3qpj9OdfiKMAPhg-pf4JtcyfU&callback=initMap';
    //script.src = `https://maps.googleapis.com/maps/api/js?key=${ENV["GOOGLE_MAPS_KEY"]}&callback=initMap`;
    script.defer = true;
    script.async = true;

    // Attach your callback function to the `window` object
    window.initMap = function() {
      let location = {
        lat: parseFloat(mapContainer.dataset.latitude),
        lng: parseFloat(mapContainer.dataset.longitude)
      };
      var map;
      map = new google.maps.Map(mapContainer, {
        center: location,
        zoom: 10
      });
      var marker = new google.maps.Marker({ position: location, map: map });
    };

    // Append the 'script' element to 'head'
    document.head.appendChild(script);
  }
})
