// Set new default font family and font color to mimic Bootstrap's default styling
(Chart.defaults.global.defaultFontFamily = "Metropolis"),
'-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';
Chart.defaults.global.defaultFontColor = "#858796";
Chart.defaults.global.maintainAspectRatio = false;

const tooltipOptions = {
  backgroundColor: "rgb(255,255,255)",
  bodyFontColor: "#858796",
  titleMarginBottom: 10,
  titleFontColor: "#6e707e",
  titleFontSize: 14,
  borderColor: "#dddfeb",
  borderWidth: 1,
  xPadding: 15,
  yPadding: 15,
  displayColors: false,
  intersect: false,
  mode: "index",
  caretPadding: 10,
  callbacks: {
    label: function(tooltipItem, chart) {
      let dataset = chart.datasets[tooltipItem.datasetIndex]
      let value = tooltipItem.yLabel
      let total = dataset.data.reduce((function(a, b){ return a + b }))
      let percentage = Math.round(value * 100 / total)
      return `${dataset.label || ""}: ${numberFormat(value)} (${percentage}%)`;
    }
  }
}

function numberFormat(number, decimals, dec_point, thousands_sep) {
  // *     example: numberFormat(1234.56, 2, ',', ' ');
  // *     return: '1 234,56'
  number = (number + "").replace(",", "").replace(" ", "");
  let n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = typeof thousands_sep === "undefined" ? "." : thousands_sep,
      dec = typeof dec_point === "undefined" ? "," : dec_point,
      s = "",
      toFixedFix = function(n, prec) {
          let k = Math.pow(10, prec);
          return "" + Math.round(n * k) / k;
      };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
      s[1] = s[1] || "";
      s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
}

function createDataset(data) {
  let dataset = {
    label: data.label,
    backgroundColor: data.color,
    borderColor: data.color,
    fill: false,
    data: data.data
  }

  return dataset;
}

function createDatasets(datasets) {
  let data = []

  for (let dataset in datasets) {
    data.push(createDataset(datasets[dataset]));
  }

  return data;
}

function createBarChart(id) {
  let chartCanvas = $(`#${id}`);
  if (chartCanvas.length) {
    let data = chartCanvas.data("data");

    new Chart(chartCanvas, {
      type: "bar",
      data: {
        labels: data.labels,
        datasets: createDatasets(data.datasets)
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 0
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
              drawBorder: false
            },
            stacked: true,
          }],
          yAxes: [{
            ticks: {
              padding: 2,
              callback: function(value, index, values) {
                return numberFormat(value);
              }
            },
            gridLines: {
              color: "rgb(234, 236, 244)",
              zeroLineColor: "rgb(234, 236, 244)",
              drawBorder: false,
              borderDash: [2],
              zeroLineBorderDash: [2]
            },
            stacked: true
          }]
        },
        legend: {
          labels: {
            padding: 15
          }
        },
        tooltips: tooltipOptions
      }
    });
  }
}

function createLineChart(id) {
  let chartCanvas = $(`#${id}`);
  if (chartCanvas.length) {
    let data = chartCanvas.data("data");

    new Chart(chartCanvas, {
      type: "line",
      data: {
        labels: data.labels,
        datasets: createDatasets(data.datasets)
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 0
          }
        },
        scales: {
          xAxes: [{
            time: {
              unit: "date"
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
          }],
          yAxes: [{
            ticks: {
              padding: 2,
              callback: function(value, index, values) {
                return numberFormat(value);
              }
            },
            gridLines: {
              color: "rgb(234, 236, 244)",
              zeroLineColor: "rgb(234, 236, 244)",
              drawBorder: false,
              borderDash: [2],
              zeroLineBorderDash: [2]
            }
          }]
        },
        legend: {
          labels: {
            padding: 15
          }
        },
        tooltips: tooltipOptions
      }
    });
  }
}

function createPieChart(id) {
  let chartCanvas = $(`#${id}`);
  if (chartCanvas.length) {
    let data = chartCanvas.data("data");
    new Chart(chartCanvas, {
      type: "pie",
      data: {
        labels: data.labels,
        datasets: [
          {
            data: data.data,
            backgroundColor: data.colors
          }
        ]
      },
			options: {
        legend: {
          position: "bottom"
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, chart) {
              let datasetLabel = chart.labels[tooltipItem.index] || "";
              let datasetValue = chart.datasets[0].data[tooltipItem.index] || 0;
              return datasetLabel + ": " + numberFormat(datasetValue);
            }
          }
        }
      }
    });
  }
}

export { createBarChart, createLineChart, createPieChart }
