// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("google_maps.js")
require("dashboard")
require("charts")
require("msi_selector.js")
require("msi_selector_requests.js")


// jquery
import $ from 'jquery';
global.$ = $
global.jQuery = $

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import Chart from "chart.js";
import 'bootstrap/dist/js/bootstrap';
import "@fortawesome/fontawesome-free/js/all";

require("../stylesheets/application.scss")

const feather = require("feather-icons");
document.addEventListener("turbolinks:load", function() {
  feather.replace();

  // Find all select boxes that have an ID attribute containing "birthday"
  // and wrap a div tag around them with a certain class
  $('.month-year-select').wrap('<div class="col-md-6">');

  $("#select_all").click(function(e) {
    e.preventDefault();
    $("input[type=checkbox]").prop("checked", true);
  });

  /*!
    * Start Bootstrap - SB Admin Pro v1.1.2 (https://shop.startbootstrap.com/product/sb-admin-pro)
    * Copyright 2013-2020 Start Bootstrap
    * Licensed under SEE_LICENSE (https://github.com/BlackrockDigital/sb-admin-pro/blob/master/LICENSE)
    */
  (function($) {
    "use strict";

    // Enable Bootstrap tooltips via data-attributes globally
    $('[data-toggle="tooltip"]').tooltip();

    // Enable Bootstrap popovers via data-attributes globally
    $('[data-toggle="popover"]').popover();

    $(".popover-dismiss").popover({
      trigger: "focus"
    });

    // Toggle the side navigation
    $("#sidebarToggle").on("click", function(e) {
      e.preventDefault();
      $("body").toggleClass("sidenav-toggled");
    });

    // Activate Bootstrap scrollspy for the sticky nav component
    $("body").scrollspy({
      target: "#stickyNav",
      offset: 82
    });

    // Scrolls to an offset anchor when a sticky nav link is clicked
    $('.nav-sticky a.nav-link[href*="#"]:not([href="#"])').click(function() {
      if (
        location.pathname.replace(/^\//, "") ==
          this.pathname.replace(/^\//, "") &&
        location.hostname == this.hostname
      ) {
        var target = $(this.hash);
        target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
        if (target.length) {
          $("html, body").animate(
            {
              scrollTop: target.offset().top - 81
            },
            200
          );
          return false;
        }
      }
    });

    // Click to collapse responsive sidebar
    $("#layoutSidenav_content").click(function() {
      const BOOTSTRAP_LG_WIDTH = 992;
      if (window.innerWidth >= BOOTSTRAP_LG_WIDTH) {
        return;
      }
      if ($("body").hasClass("sidenav-toggled")) {
        $("body").toggleClass("sidenav-toggled");
      }
    });

    // Init sidebar
    let activatedPath = window.location.pathname.match(/([\w-]+\.html)/, '$1');

    if (activatedPath) {
      activatedPath = activatedPath[0];
    }
    else {
      activatedPath = 'index.html';
    }

    let targetAnchor = $('[href="' + activatedPath + '"]');
    let collapseAncestors = targetAnchor.parents('.collapse');

    targetAnchor.addClass('active');

    collapseAncestors.each(function() {
      $(this).addClass('show');
      $('[data-target="#' + this.id +  '"]').removeClass('collapsed');
    })
  })(jQuery);
})
