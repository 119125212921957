import { createBarChart, createLineChart, createPieChart } from 'charts';

document.addEventListener("turbolinks:load", function() {
  createBarChart('countries-amounts');
  createBarChart('countries-transactions');
  createLineChart('daily-amount');
  createLineChart('daily-transactions');
  createPieChart('business-chains-amounts');
  createPieChart('card-types-transactions');
})
